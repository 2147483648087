import { ApuracaoProps } from '../types';

export const sumKeyIcms = (
  arr: ApuracaoProps[],
  key: keyof ApuracaoProps,
): number => {
  return arr.reduce((acc, obj) => {
    const value = obj[key];
    const numericValue = typeof value === 'number' ? value : parseFloat(value);
    return acc + (Number.isNaN(numericValue) ? 0 : numericValue);
  }, 0);
};
