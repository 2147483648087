import React from 'react';
import { ContainerApuracao } from './styles';
import FooterDefault from '~/components/FooterDefault';
import { SearchFilters } from './components/SearchFilters';
import { GridIcms } from './components/GridIcms';
import { TotalizadoresIcms } from './components/TotalizadoresIcms';
import { PrintButton } from './components/PrintButton';

export const ApuracaoIcmsContent: React.FC = () => {
  return (
    <ContainerApuracao>
      <h3>APURAÇÃO ICMS</h3>
      <section className="content">
        <SearchFilters />
        <GridIcms />
        <TotalizadoresIcms />
        <div className="content-print-button">
          <PrintButton />
        </div>
      </section>
      <FooterDefault codTela={298} />
    </ContainerApuracao>
  );
};
