import pdfMake from 'pdfmake/build/pdfmake';
import {
  AliquotaApuracaoProps,
  ApuracaoGridProps,
  LojaApuracaoProps,
} from '../types';
import { moneyFormat, pontuaCpfCnpj } from '~/utils/functions';
import { format } from 'date-fns';

class ApuracaoIcmsPDF {
  private docDefinition: any;

  constructor(
    private items: ApuracaoGridProps[],
    private dates: { dta_inicio: Date; dta_fim: Date },
    private loja: LojaApuracaoProps,
    private aliquotas: AliquotaApuracaoProps[],
  ) {
    this.docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [30, 85, 30, 40], // [left, top, right, bottom]
      defaultStyle: {
        fontSize: 9.5,
      },
      content: this.createContent(),
      header: this.createHeader(),
    };
  }

  private createContent() {
    const { items, aliquotas } = this;

    const filterItemsByCfop = (cfopStart: string[]) =>
      items.filter((item) =>
        cfopStart.some((start) => item.cfop.startsWith(start)),
      );

    const createSectionContent = (
      header: string,
      bodyItems: any[],
      bodyLabel: 'Entradas' | 'Saídas',
    ) => {
      const sectionContent = [];

      sectionContent.push(ApuracaoIcmsPDF.createSectionHeaderContent(header));
      sectionContent.push(
        ApuracaoIcmsPDF.createTableBodyContent(bodyItems, bodyLabel),
      );

      return sectionContent;
    };

    const icmsEntradas = filterItemsByCfop(['1', '2']);
    const icmsEntradasDentroEstado = filterItemsByCfop(['1']);
    const icmsEntradasOutrosEstado = filterItemsByCfop(['2']);

    const icmsSaidas = filterItemsByCfop(['5', '6']);
    const icmsSaidasDentroEstado = filterItemsByCfop(['5']);
    const icmsSaidasOutrosEstado = filterItemsByCfop(['6']);

    const content: any[] = [];

    if (icmsEntradas.length > 0) {
      content.push(ApuracaoIcmsPDF.createTableHeaderContent('Entradas'));

      if (icmsEntradasDentroEstado.length > 0) {
        content.push(
          ...createSectionContent(
            '1000 - Entradas e/ou Aquisição de Serviços no Estado',
            icmsEntradasDentroEstado,
            'Entradas',
          ),
        );
      }

      if (icmsEntradasOutrosEstado.length > 0) {
        content.push(
          ...createSectionContent(
            '2000 - Entradas e/ou Aquisição de Serviços de outros Estados',
            icmsEntradasOutrosEstado,
            'Entradas',
          ),
        );
      }

      content.push(ApuracaoIcmsPDF.createTotal(icmsEntradas, 'Entradas'));
      content.push({ text: '', pageBreak: 'after' });
    }

    if (icmsSaidas.length > 0) {
      content.push(ApuracaoIcmsPDF.createTableHeaderContent('Saídas'));

      if (icmsSaidasDentroEstado.length > 0) {
        content.push(
          ...createSectionContent(
            '5000 - Saídas e/ou Prestações de Serviços no Estado',
            icmsSaidasDentroEstado,
            'Saídas',
          ),
        );
      }

      if (icmsSaidasOutrosEstado.length > 0) {
        content.push(
          ...createSectionContent(
            '6000 - Saídas e/ou Prestações de Serviços para outros Estados',
            icmsSaidasOutrosEstado,
            'Saídas',
          ),
        );
      }

      content.push(ApuracaoIcmsPDF.createTotal(icmsSaidas, 'Saídas'));

      content.push(
        ApuracaoIcmsPDF.createHeaderResumoApuracao(
          'RESUMO DA APURAÇÃO DO IMPOSTO',
          false,
        ),
      );
      content.push(
        ApuracaoIcmsPDF.createGridResumoApuracao(
          'Saídas',
          icmsSaidas,
          'DÉBITO DO IMPOSTO',
          'VALORES',
        ),
      );
    }

    if (icmsEntradas.length > 0) {
      content.push(
        ApuracaoIcmsPDF.createGridResumoApuracao(
          'Entradas',
          icmsEntradas,
          'CRÉDITO DO IMPOSTO',
          '',
        ),
      );
    }

    content.push(
      ApuracaoIcmsPDF.createHeaderResumoApuracao('APURAÇÃO DOS SALDOS', false),
    );
    content.push(
      ApuracaoIcmsPDF.createApuracaoDosSaldos(icmsEntradas, icmsSaidas),
    );
    content.push(
      ApuracaoIcmsPDF.createHeaderResumoApuracao(
        'INFORMAÇÕES COMPLEMENTARES',
        false,
        true,
      ),
    );
    content.push(ApuracaoIcmsPDF.createGridGuia());
    content.push(
      ApuracaoIcmsPDF.createHeaderResumoApuracao(
        'TABELA DE OCORRÊNCIAS',
        false,
        true,
      ),
    );
    content.push(ApuracaoIcmsPDF.createTabelaOcorrencias());

    if (aliquotas.length > 0 && icmsSaidas.length === 0)
      content.push({ text: '', pageBreak: 'after' });

    if (aliquotas.length > 0) {
      if (icmsEntradas.length > 0 && icmsSaidas.length > 0)
        content.push({ text: '', pageBreak: 'after' });

      content.push(
        ApuracaoIcmsPDF.createHeaderResumoApuracao(
          'Resumo por Alíquota',
          true,
          icmsEntradas.length > 0 && icmsSaidas.length > 0,
        ),
      );
      content.push(this.createResumoPorAliquota());
    }

    return content;
  }

  private static createTableHeaderContent(tipo: 'Entradas' | 'Saídas') {
    return {
      table: {
        widths: ['12%', '14.5%', '73.5%'],
        headerRows: 2,
        body: [
          [
            {
              text: '',
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: '',
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: `${tipo}\nIcms - Valores Fiscais`,
              style: 'tableHeader',
              alignment: 'left',
              bold: true,
              margin: [45, -2, 0, 0],
            },
          ],
          [
            {
              text: 'Codificação\nContab/Fiscal',
              bold: true,
              margin: [0, 10, 0, 0],
            },
            {
              text: 'Valores\nContábeis',
              bold: true,
              margin: [10, 10, 0, 0],
            },
            {
              layout: {
                hLineColor(i: number, node: any) {
                  return i === 0 || i === node.table.body.length
                    ? 'white'
                    : 'black';
                },
                vLineColor(i: number, node: any) {
                  return i === 0 || i === node.table.widths.length
                    ? 'white'
                    : 'black';
                },
              },
              table: {
                widths: ['50%', '50%'],
                headerRows: 2,
                body: [
                  [
                    {
                      text: 'Operações com Crédito do Imposto',
                      bold: true,
                    },
                    {
                      text: 'Operações sem Crédito do Imposto',
                      bold: true,
                    },
                  ],
                  [
                    {
                      table: {
                        widths: ['50%', '50%'],
                        headerRows: 1,
                        body: [
                          [
                            {
                              text: 'Base Cálculo',
                              border: [false, false, true, false],
                              bold: true,
                            },
                            {
                              text: `Imposto ${
                                tipo === 'Entradas' ? 'Creditado' : 'Debitado'
                              }`,
                              border: [false, false, false, false],
                              bold: true,
                              fontSize: 9,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      table: {
                        widths: ['46.9%', '33%', '33%'],
                        headerRows: 1,
                        body: [
                          [
                            {
                              text: 'Isentos/Não Trib',
                              border: [false, false, true, false],
                              bold: true,
                              fontSize: 9,
                            },
                            {
                              text: 'Outras',
                              border: [false, false, false, false],
                              bold: true,
                            },
                            {
                              text: 'Icms-ST',
                              border: [false, false, false, false],
                              bold: true,
                            },
                          ],
                        ],
                      },
                    },
                  ],
                ],
              },
            },
          ],
        ],
      },
    };
  }

  private static createSectionHeaderContent(text: string) {
    return {
      table: {
        widths: ['100%'],
        body: [
          [
            {
              text,
              border: [true, false, true, false], // [left, top, right, bottom]
              margin: [0, 5, 0, 0],
              bold: true,
            },
          ],
        ],
      },
    };
  }

  private static createTableBodyContent(
    items: ApuracaoGridProps[],
    tipo: 'Entradas' | 'Saídas',
  ) {
    const isEntrada = tipo === 'Entradas';

    type TableCell = {
      text: string | number;
      alignment: 'left' | 'right';
      border: boolean[];
      bold?: boolean;
      fillColor?: string;
      margin?: number[]; // [left, top, right, bottom]
    };

    const formatText = (
      text: string | number,
      alignment: 'left' | 'right',
      border: boolean[],
      margin?: number[],
    ): TableCell => ({
      text,
      alignment,
      border,
      margin: margin ?? [0, -2, 0, 0],
    });

    const body = items.map((item) => [
      formatText(item.cfop, 'left', [true, false, false, false], [0, -2, 0, 0]),
      formatText(
        isEntrada ? item.val_contabil : item.val_contabil_sai,
        'right',
        [false, false, false, false],
      ),
      formatText(
        isEntrada ? item.val_base_calculo : item.val_base_calculo_sai,
        'right',
        [false, false, false, false],
      ),
      formatText(
        isEntrada ? item.val_imposto_credito : item.val_imposto_debito,
        'right',
        [false, false, false, false],
      ),
      formatText(isEntrada ? item.val_isentas : item.val_isentas_sai, 'right', [
        false,
        false,
        false,
        false,
      ]),
      formatText(isEntrada ? item.val_outras : item.val_outras_sai, 'right', [
        false,
        false,
        false,
        false,
      ]),
      formatText(item.val_icms_st, 'right', [false, false, true, false]),
    ]);

    const subTotals = body[0].map((_, idx) => {
      if (idx === 0) return 'Sub-Total';

      const sum = body.reduce((acc, row) => {
        const value = parseFloat(
          String(row[idx].text).replace('.', '').replace(',', '.'),
        );
        return acc + value;
      }, 0);

      return sum;
    });

    const subTotalRow = subTotals.map(
      (value, idx): TableCell => ({
        text: typeof value === 'number' ? moneyFormat(value.toFixed(2)) : value,
        alignment: idx === 0 ? 'left' : 'right',
        border: [idx === 0, true, idx === body[0].length - 1, true],
        bold: idx === 0,
        fillColor: '#A9A9A9',
        margin: [idx === 0 ? 1 : 0, 0, 0, 0],
      }),
    );

    return {
      table: {
        widths: ['13%', '12%', '18%', '17%', '19%', '12%', '9%'],
        body: [...body, subTotalRow],
      },
    };
  }

  private static createTotal(
    items: ApuracaoGridProps[],
    tipo: 'Entradas' | 'Saídas',
  ) {
    const parseValue = (value: string | number) =>
      parseFloat(String(value).replace('.', '').replace(',', '.'));

    const calcTotal = (key: keyof ApuracaoGridProps) =>
      items.reduce((acc, row) => acc + parseValue(row[key]), 0);

    const getTotalRowData = (key: keyof ApuracaoGridProps) =>
      moneyFormat(calcTotal(key).toFixed(2));

    const totalRow = [
      {
        text: 'Total',
        alignment: 'left',
        border: [true, false, false, true], // [left, top, right, bottom]
        bold: true,
        fillColor: '#A9A9A9',
        margin: [1, 0, 0, 0],
      },
      {
        text: getTotalRowData(
          tipo === 'Entradas' ? 'val_contabil' : 'val_contabil_sai',
        ),
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: '#A9A9A9',
      },
      {
        text: getTotalRowData(
          tipo === 'Entradas' ? 'val_base_calculo' : 'val_base_calculo_sai',
        ),
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: '#A9A9A9',
      },
      {
        text: getTotalRowData(
          tipo === 'Entradas' ? 'val_imposto_credito' : 'val_imposto_debito',
        ),
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: '#A9A9A9',
      },
      {
        text: getTotalRowData(
          tipo === 'Entradas' ? 'val_isentas' : 'val_isentas_sai',
        ),
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: '#A9A9A9',
      },
      {
        text: getTotalRowData(
          tipo === 'Entradas' ? 'val_outras' : 'val_outras_sai',
        ),
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: '#A9A9A9',
      },
      {
        text: getTotalRowData('val_icms_st'),
        alignment: 'right',
        border: [false, false, true, true],
        fillColor: '#A9A9A9',
      },
    ];

    return {
      table: {
        widths: ['13%', '12%', '18%', '17%', '19%', '12%', '9%'],
        body: [totalRow],
      },
    };
  }

  private static createHeaderResumoApuracao(
    text: string,
    fillColor: boolean,
    borderTop?: boolean,
  ) {
    return {
      table: {
        widths: ['100%'],
        headerRows: 1,
        body: [
          [
            {
              text,
              bold: true,
              alignment: 'center',
              border: [true, borderTop ?? false, true, true],
              fillColor: fillColor ? '#A9A9A9' : 'white',
            },
          ],
        ],
      },
    };
  }

  private static createGridResumoApuracao(
    tipo: 'Entradas' | 'Saídas',
    apuracoes: ApuracaoGridProps[],
    centerLabel: string,
    rightLabel: string,
  ) {
    const parseValue = (value: string | number) =>
      parseFloat(String(value).replace('.', '').replace(',', '.'));

    const sumKey = (key: keyof ApuracaoGridProps) =>
      apuracoes.reduce((acc, row) => acc + parseValue(row[key]), 0);

    const columnAux = (type: 'Entradas' | 'Saídas') =>
      type === 'Saídas'
        ? {
            table: {
              widths: ['50%', '50%'],
              headerRows: 1,
              body: [
                [
                  {
                    text: 'COLUNA AUXILIAR',
                    alignment: 'center',
                    fontSize: 8,
                    border: [false, false, true, false],
                  },
                  {
                    text: 'SOMAS',
                    alignment: 'center',
                    fontSize: 8,
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
          }
        : { text: '', border: [false, false, true, false] };

    const createTableRowDeb = (
      description: string,
      value: string,
      hasTopBorder = false,
    ) => {
      return [
        {
          text: description,
          alignment: 'left',
          border: [true, hasTopBorder, false, true], // [left, top, right, bottom]
          fontSize: 9,
        },
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  alignment: 'right',
                  border: [false, false, true, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
                {
                  text: value,
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
              ],
            ],
          },
        },
      ];
    };

    const debImposto = [
      createTableRowDeb(
        '001 - POR SAÍDA/PRESTAÇÕES COM DÉBITO DO IMPOSTO\n002 - OUTROS DÉBITOS',
        `${moneyFormat(sumKey('val_imposto_debito').toFixed(2))}\n0,00`,
      ),
      createTableRowDeb('003 - ESTORNO DE CRÉDITOS', '0,00'),
      createTableRowDeb(
        '005 - SUB-TOTAL',
        `${moneyFormat(sumKey('val_imposto_debito').toFixed(2))}`,
      ),
    ];

    const credImposto = [
      [
        {
          text: '006 - POR ENTRADAS COM CRÉDITO DO IMPOSTO\n007 - OUTROS CRÉDITOS\nSUB-ITEM.: - ',
          alignment: 'left',
          border: [true, false, false, true], // [left, top, right, bottom]
          fontSize: 9,
        },
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 1,
            body: [
              [
                {
                  text: '\n\n0,00',
                  alignment: 'right',
                  border: [false, false, true, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
                {
                  text: `${moneyFormat(
                    sumKey('val_imposto_credito').toFixed(2),
                  )}\n0,00`,
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
              ],
            ],
          },
        },
      ],
      [
        {
          text: '008 - ESTORNO DE DÉBITOS ',
          alignment: 'left',
          border: [true, false, false, true], // [left, top, right, bottom]
          fontSize: 9,
        },
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  alignment: 'right',
                  border: [false, false, true, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
                {
                  text: '0,00',
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
              ],
            ],
          },
        },
      ],
      [
        {
          text: '010 - SUB-TOTAL',
          alignment: 'left',
          border: [true, false, false, true], // [left, top, right, bottom]
          fontSize: 9,
        },
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  alignment: 'right',
                  border: [false, false, true, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
                {
                  text: `${moneyFormat(
                    sumKey('val_imposto_credito').toFixed(2),
                  )}`,
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
              ],
            ],
          },
        },
      ],
      [
        {
          text: '011 - SALDO CREDOR DO PERÍODO ANTERIOR',
          fontSize: 9,
        },
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
                {
                  text: '0,00',
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
              ],
            ],
          },
        },
      ],
      [
        {
          text: '012 - TOTAL',
          fontSize: 8,
        },
        {
          table: {
            widths: ['50%', '50%'],
            headerRows: 1,
            body: [
              [
                {
                  text: '',
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
                {
                  text: `${moneyFormat(
                    sumKey('val_imposto_credito').toFixed(2),
                  )}`,
                  alignment: 'right',
                  border: [false, false, false, false], // [left, top, right, bottom]
                  fontSize: 9,
                },
              ],
            ],
          },
        },
      ],
    ];

    const impostos = tipo === 'Saídas' ? debImposto : credImposto;

    return {
      table: {
        widths: ['70%', '30%'],
        headerRows: 4,
        body: [
          [
            {
              text: centerLabel,
              bold: true,
              alignment: 'center',
              margin: [150, 0, 0, 0],
              border: [true, false, false, true], // [left, top, right, bottom]
            },
            {
              text: rightLabel,
              bold: true,
              alignment: 'right',
              border: [false, false, true, true],
            },
          ],

          [{ text: '', border: [true, false, false, false] }, columnAux(tipo)],
          ...impostos,
        ],
      },
    };
  }

  private static createApuracaoDosSaldos(
    entradas: ApuracaoGridProps[],
    saidas: ApuracaoGridProps[],
  ) {
    const parseValue = (value: string | number): number =>
      parseFloat(String(value).replace('.', '').replace(',', '.'));

    const calculateTotal = (
      items: ApuracaoGridProps[],
      key: keyof ApuracaoGridProps,
    ): number => items.reduce((acc, item) => acc + parseValue(item[key]), 0);

    const totalCredito = calculateTotal(entradas, 'val_imposto_credito');
    const totalDebitos = calculateTotal(saidas, 'val_imposto_debito');
    const total = totalCredito - totalDebitos;

    const formattedTotal = moneyFormat(total.toFixed(2));

    return {
      table: {
        widths: ['71.3%', '13.7%', '15%'],
        body: [
          [
            {
              text: `013 - SALDO DEVEDOR (Débito menos Crédito)\n014 - DEDUÇÕES\n\n015 - IMPOSTO À RECOLHER\n016 - SALDO CREDOR (Crédito menos Débito) a transportar para o período seguinte`,
              fontSize: 9,
              alignment: 'left',
              border: [true, false, true, false], // [left, top, right, bottom]
            },
            { text: '', border: [false, false, true, false] },
            {
              text: `${formattedTotal}\n0,00\n\n${formattedTotal}\n0,00`,
              fontSize: 9,
              alignment: 'right',
              border: [false, false, true, false],
            },
          ],
        ],
      },
    };
  }

  private static createGridGuia() {
    const cellStyle = {
      fontSize: 9,
      bold: true,
    };

    const leftCellBorder = [true, false, false, true];
    const rightCellBorder = [false, false, true, true];

    const guiaRecolhimentoCell = {
      text: 'GUIA DE RECOLHIMENTO',
      border: leftCellBorder,
      ...cellStyle,
    };

    const guiaInformacaoCell = {
      text: 'GUIA DE INFORMAÇÃO',
      border: rightCellBorder,
      ...cellStyle,
    };

    const contentCellStyle = {
      fontSize: 9,
    };

    const guiaRecolhimentoContent = {
      text: 'NÚMERO:\nDATA:\nVALOR: 0,00\nÓRGÃO ARRECADADOR:\nOBS.:',
      border: [true, false, false, false],
      ...contentCellStyle,
    };

    const guiaInformacaoContent = {
      text: 'NÚMERO:\nDATA:\nVALOR: 0,00\nÓRGÃO ARRECADADOR:',
      border: [false, false, true, false],
      ...contentCellStyle,
    };

    return {
      table: {
        widths: ['50%', '50%'],
        body: [
          [guiaRecolhimentoCell, guiaInformacaoCell],
          [guiaRecolhimentoContent, guiaInformacaoContent],
        ],
      },
    };
  }

  private static createTabelaOcorrencias() {
    return {
      table: {
        widths: ['15%', '35%', '50%'],
        body: [
          [
            {
              text: 'CÓDIGO',
              fontSize: 9,
              border: [true, false, false, true], // [left, top, right, bottom]
            },
            {
              text: 'DESCRIÇÃO',
              fontSize: 9,
              border: [false, false, false, true],
            },
            {
              text: 'BASE LEGAL',
              fontSize: 9,
              border: [false, false, true, true],
            },
          ],
        ],
      },
    };
  }

  private createResumoPorAliquota() {
    const { aliquotas } = this;

    const createValueColumn = (key: keyof AliquotaApuracaoProps) =>
      aliquotas.map((column) => ({
        [key]: moneyFormat(column[key].toFixed(2)),
      }));

    const formatColumn = (
      key: keyof AliquotaApuracaoProps,
      suffix = '',
      alignment: 'left' | 'center' | 'right' = 'right',
      margin?: number[],
    ) =>
      createValueColumn(key).map((value: any) => ({
        text: `${value[key]}${suffix}`,
        fontSize: 8,
        alignment,
        margin,
      }));

    const columnAliquotas = formatColumn(
      'per_aliq_icms',
      '%',
      'center',
      [0, 0, 50, 0],
    );
    const columnBaseCalculoEntrada = formatColumn(
      'val_bc_icms_ent',
      '',
      'right',
      [0, 0, 32, 0],
    );
    const columnImpostoEntrada = formatColumn(
      'val_icms_ent',
      '',
      'right',
      [0, 0, 34, 0],
    );
    const columnBaseCalculoSaida = formatColumn('val_bc_icms_sai');
    const columnImpostoSaida = formatColumn('val_icms_sai');

    const minLength = Math.min(
      columnAliquotas.length,
      columnBaseCalculoEntrada.length,
      columnImpostoEntrada.length,
      columnBaseCalculoSaida.length,
      columnImpostoSaida.length,
    );

    return {
      layout: {
        hLineColor(i: number, node: any) {
          return i === 0 || i === node.table.body.length ? 'black' : 'white';
        },
        vLineColor(i: number, node: any) {
          return i === 0 || i === node.table.widths.length ? 'black' : 'white';
        },
      },
      table: {
        widths: ['60%', '40%'],
        body: [
          [
            {
              text: 'Entradas',
              alignment: 'center',
              border: [true, false, false, false], // [left, top, right, bottom]
              bold: true,
              fontSize: 9,
              margin: [0, 0, 0, -3], // [left, top, right, bottom]
            },
            {
              text: 'Saídas',
              alignment: 'center',
              border: [false, false, true, false],
              bold: true,
              fontSize: 9,
              margin: [0, 0, 0, -3],
            },
          ],
          [
            {
              layout: 'noBorders',
              table: {
                widths: ['33.33%', '33.33%', '33.33%'],
                body: [
                  [
                    { text: 'Alíquota', fontSize: 9, alignment: 'left' },
                    {
                      text: 'Base de Cálculo',
                      fontSize: 9,
                      alignment: 'left',
                      margin: [3, 0, 0, 0],
                    },
                    { text: 'Imposto', fontSize: 9, alignment: 'center' },
                  ],
                  ...(minLength > 0
                    ? Array.from({ length: minLength }).map((_, index) => [
                        columnAliquotas[index] || { text: '', fontSize: 8.5 },
                        columnBaseCalculoEntrada[index] || {
                          text: '',
                          fontSize: 8,
                        },
                        columnImpostoEntrada[index] || {
                          text: '',
                          fontSize: 8,
                        },
                      ])
                    : [
                        [
                          {
                            text: 'Sem dados',
                            fontSize: 8,
                            colSpan: 3,
                            alignment: 'center',
                          },
                          {},
                          {},
                        ],
                      ]),
                ],
              },
            },
            {
              layout: 'noBorders',
              table: {
                widths: ['50%', '50%'],
                body: [
                  [
                    {
                      text: 'Base de Cálculo',
                      fontSize: 9,
                      alignment: 'right',
                    },
                    { text: 'Imposto', fontSize: 9, alignment: 'right' },
                  ],
                  ...(minLength > 0
                    ? Array.from({ length: minLength }).map((_, index) => [
                        columnBaseCalculoSaida[index] || {
                          text: '',
                          fontSize: 8,
                        },
                        columnImpostoSaida[index] || {
                          text: '',
                          fontSize: 8,
                        },
                      ])
                    : [
                        [
                          {
                            text: 'Sem dados',
                            fontSize: 8,
                            colSpan: 2,
                            alignment: 'center',
                          },
                          {},
                        ],
                      ]),
                ],
              },
            },
          ],
        ],
      },
    };
  }

  private createHeader() {
    return (currentPage: number) => {
      const formatPageNumber = (page: number) => {
        return page.toString().padStart(4, '0');
      };

      const { loja, dates } = this;
      const dtaInicio = format(dates.dta_inicio, 'dd/MM/yyyy');
      const dtaTermino = format(dates.dta_fim, 'dd/MM/yyyy');

      return [
        {
          layout: 'noBorders',
          table: {
            widths: ['100%'],
            headerRows: 3,
            body: [
              [
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['52.7%', 'auto'],
                    body: [
                      [
                        {
                          text: `${loja.des_loja}`,
                          alignment: 'left',
                          bold: true,
                          margin: [0, 0, 0, 0],
                        },
                        {
                          text: `Relatório Apuração ICMS - Período:(${dtaInicio} - ${dtaTermino})`,
                          alignment: 'right',
                          fontSize: 9,
                          margin: [0, 0, 0, 0],
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['85%', '15%'],
                    body: [
                      [
                        {
                          text: `${loja.des_logradouro} - Nº: ${loja.num_endereco} - ${loja.des_bairro} CEP ${loja.num_cep} ${loja.des_cidade} - ${loja.des_uf}`,
                          alignment: 'left',
                          bold: true,
                          margin: [0, -7.5, 0, 0],
                        },
                        {
                          text: `Folha Nº: ${formatPageNumber(currentPage)}`,
                          alignment: 'right',
                          margin: [0, -7.5, 0, 0],
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  layout: 'noBorders',
                  table: {
                    widths: ['100%'],
                    body: [
                      [
                        {
                          text: `CNPJ: ${pontuaCpfCnpj(
                            loja.num_cnpj,
                          )}\nLivro Nº: Livro Nº:`,
                          alignment: 'left',
                          bold: true,
                          margin: [0, -7.5, 0, 0],
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
          margin: [30, 30, 30, 10],
        },
        ApuracaoIcmsPDF.createDividerHeader(),
      ];
    };
  }

  private static createDividerHeader() {
    return {
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 0,
          x2: 535,
          y2: 0,
          lineWidth: 1,
          lineColor: '#000000',
        },
      ],
      margin: [30, -13, 30, 0],
    };
  }

  public generatePDF(): void {
    const pdfDocGenerator = pdfMake.createPdf(this.docDefinition);
    pdfDocGenerator.open();
  }
}

export default ApuracaoIcmsPDF;
