import React, { createContext, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AliquotaApuracaoProps,
  ApuracaoGridProps,
  ApuracaoIcmsContextData,
  ApuracaoIcmsProviderProps,
  DisableButtonProps,
  DisableInputProps,
  LojaApuracaoProps,
  ResponseApuracaoProps,
  TotalizadoresIcmsProps,
  UseFormProps,
} from '../types';
import { resetStatesApuracaoIcms } from '../utils/resetStatesApuracaoIcms';
import { toast } from 'react-toastify';
import { format, isSameMonth, isSameYear, parseISO } from 'date-fns';
import { serviceApuracao } from '../services';
import { nanoid } from 'nanoid';
import { formatApuracaoIcms } from '../utils/formatApuracaoIcms';
import { sumKeyIcms } from '../utils/sumKeyIcms';

export const ApuracaoIcmsContext = createContext({} as ApuracaoIcmsContextData);

export const ApuracaoIcmsProvider: React.FC<ApuracaoIcmsProviderProps> = ({
  children,
}) => {
  const [disableInput, setDisableInput] = useState<DisableInputProps>({
    lojas: false,
    datas: false,
  });
  const [disableButton, setDisableButton] = useState<DisableButtonProps>({
    search: false,
    cancel: true,
    clear: false,
    print: true,
  });
  const [lojaSelected, setLojaSelected] = useState<number[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [apuracaoGridItems, setApuracaoGridItems] = useState<
    ApuracaoGridProps[]
  >([]);
  const [totalizadores, setTotalizadores] = useState<TotalizadoresIcmsProps>({
    cred_icms: 0,
    deb_icms: 0,
    imp_icms: 0,
  });
  const [infoLoja, setInfoLoja] = useState<LojaApuracaoProps | null>(null);
  const [periodo, setPeriodo] = useState<{
    dta_inicio: Date;
    dta_termino: Date;
  }>({
    dta_inicio: new Date(),
    dta_termino: new Date(),
  });
  const [aliquotas, setAliquotas] = useState<AliquotaApuracaoProps[]>([]);

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm();

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  const handleChangeLoja = useCallback((codLoja: number[]) => {
    setLojaSelected(codLoja);
  }, []);

  const startFetchingState = useCallback(() => {
    setIsFetching(true);
    setDisableInput({ lojas: true, datas: true });
    setDisableButton({ search: true, cancel: true, clear: true, print: true });
  }, []);

  const resetUIState = useCallback(() => {
    const { inputs, buttons } = resetStatesApuracaoIcms;
    setDisableInput(inputs);
    setDisableButton(buttons);
  }, []);

  const handleSuccessWithNoData = useCallback(() => {
    toast.warning('Nenhum registro encontrado com os critérios informados');
    resetUIState();
  }, [resetUIState]);

  const handleSuccessWithData = useCallback((data: ResponseApuracaoProps) => {
    setDisableInput({ lojas: true, datas: true });
    setDisableButton({
      search: true,
      cancel: false,
      clear: true,
      print: false,
    });

    const { loja, apuracao, aliquota } = data;

    setInfoLoja(loja);
    setAliquotas(aliquota);

    const apuracoes: ApuracaoGridProps[] = apuracao.map((ap) => ({
      id: nanoid(),
      ...ap,
    }));

    const totalCredIcms = sumKeyIcms(apuracao, 'val_imposto_credito');
    const totalDebIcms = sumKeyIcms(apuracao, 'val_imposto_debito');
    const totalImpIcms = totalCredIcms - totalDebIcms;

    setTotalizadores({
      cred_icms: totalCredIcms,
      deb_icms: totalDebIcms,
      imp_icms: totalImpIcms,
    });

    const formattedGridItems = formatApuracaoIcms(apuracoes);
    setApuracaoGridItems(formattedGridItems);
  }, []);

  const handleSearch = useCallback(async () => {
    const lojaSelectedLength = lojaSelected.length;

    if (lojaSelectedLength === 0) {
      toast.warning('Para prosseguir, selecione ao menos uma loja');
      return;
    }

    const dta_inicio = getValues('dta_inicio');
    const dta_fim = getValues('dta_fim');

    if (!dta_inicio || !dta_fim) {
      if (!dta_inicio) setError('dta_inicio', { type: 'required' });
      if (!dta_fim) setError('dta_fim', { type: 'required' });

      toast.warning('As datas de início e término devem ser informadas');
      return;
    }

    const inicio = parseISO(dta_inicio);
    const fim = parseISO(dta_fim);

    if (inicio > fim) {
      setError('dta_inicio', { type: 'invalid' });
      setError('dta_fim', { type: 'invalid' });
      toast.warning('A data inicial não pode ser superior à data final');
      return;
    }

    if (!isSameMonth(inicio, fim) || !isSameYear(inicio, fim)) {
      setError('dta_inicio', { type: 'invalid' });
      setError('dta_fim', { type: 'invalid' });
      toast.warning('O período deve estar dentro do mesmo mês e ano');
      return;
    }

    clearErrors('dta_inicio');
    clearErrors('dta_fim');

    setPeriodo({ dta_inicio: inicio, dta_termino: fim });

    startFetchingState();

    const { success, data, message } = await serviceApuracao.indexApuracao(
      lojaSelected,
      format(inicio, 'yyyy-MM-dd'),
      format(fim, 'yyyy-MM-dd'),
    );

    if (success) {
      if (data.apuracao.length <= 0) handleSuccessWithNoData();
      else handleSuccessWithData(data);
    } else toast.warning(message);

    setIsFetching(false);
  }, [
    clearErrors,
    getValues,
    handleSuccessWithData,
    handleSuccessWithNoData,
    lojaSelected,
    setError,
    startFetchingState,
  ]);

  const handleCancel = useCallback(() => {
    const { inputs, buttons } = resetStatesApuracaoIcms;
    setDisableInput(inputs);
    setDisableButton(buttons);
    setApuracaoGridItems([]);
    setInfoLoja(null);
    setPeriodo({ dta_inicio: new Date(), dta_termino: new Date() });
    setTotalizadores({ cred_icms: 0, deb_icms: 0, imp_icms: 0 });
    setAliquotas([]);
  }, []);

  const handleClear = useCallback(() => {
    setValue('dta_inicio', '');
    setValue('dta_fim', '');
    clearErrors(['dta_inicio', 'dta_fim']);

    const dtaIni: HTMLInputElement | null = document.querySelector(
      '[name="dta_inicio"]',
    );
    const dtaFim: HTMLInputElement | null =
      document.querySelector('[name="dta_fim"]');

    if (dtaIni) dtaIni.value = '';
    if (dtaFim) dtaFim.value = '';
  }, [clearErrors, setValue]);

  return (
    <ApuracaoIcmsContext.Provider
      value={{
        formTela,
        disableButton,
        disableInput,
        isFetching,
        apuracaoGridItems,
        totalizadores,
        infoLoja,
        periodo,
        aliquotas,

        handleSearch,
        handleCancel,
        handleClear,

        handleChangeLoja,
      }}
    >
      {children}
    </ApuracaoIcmsContext.Provider>
  );
};
