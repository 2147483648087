const inputs = {
  lojas: false,
  datas: false,
};

const buttons = {
  search: false,
  cancel: true,
  clear: false,
  print: true,
};

export const resetStatesApuracaoIcms = { inputs, buttons };
