import React, { useMemo } from 'react';
import { ContentTotalizadorIcms } from './styles';
import { useApuracaoIcms } from '../../hooks/useApuracaoIcms';
import { moneyFormat } from '~/utils/functions';

export const TotalizadoresIcms: React.FC = () => {
  const { totalizadores } = useApuracaoIcms();

  const formattedValues = useMemo(
    () => ({
      credIcms: moneyFormat(totalizadores.cred_icms.toFixed(2)),
      debIcms: moneyFormat(totalizadores.deb_icms.toFixed(2)),
      impIcms: moneyFormat(totalizadores.imp_icms.toFixed(2)),
    }),
    [totalizadores],
  );

  const items = [
    { label: 'Créd. ICMS', value: formattedValues.credIcms },
    { label: 'Débito ICMS', value: formattedValues.debIcms },
    { label: 'Imposto Apurado', value: formattedValues.impIcms },
  ];

  return (
    <ContentTotalizadorIcms>
      {items.map(({ label, value }) => (
        <span key={label}>
          <strong>{label}:</strong>
          <p>{value}</p>
        </span>
      ))}
    </ContentTotalizadorIcms>
  );
};
