import { moneyFormat } from '~/utils/functions';
import { ApuracaoGridProps } from '../types';

export const formatApuracaoIcms = (
  apuracoes: ApuracaoGridProps[],
): ApuracaoGridProps[] => {
  return apuracoes.map((apuracao) => {
    const formattedApuracao: any = { ...apuracao };

    Object.keys(formattedApuracao).forEach((key) => {
      if (key.startsWith('val_')) {
        const typedKey = key as keyof ApuracaoGridProps;
        const value = formattedApuracao[typedKey];

        if (typeof value === 'string' || typeof value === 'number')
          formattedApuracao[typedKey] = moneyFormat(value.toString()) as any;
      }
    });

    return formattedApuracao;
  });
};
