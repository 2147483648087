import React, { useCallback } from 'react';
import { FaPrint } from 'react-icons/fa';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { useApuracaoIcms } from '../../hooks/useApuracaoIcms';
import ApuracaoIcmsPDF from '../../classes/ApuracaoIcmsPDF';

export const PrintButton: React.FC = () => {
  const { disableButton, apuracaoGridItems, infoLoja, periodo, aliquotas } =
    useApuracaoIcms();

  const handlePrint = useCallback(() => {
    if (infoLoja && apuracaoGridItems.length > 0) {
      const { dta_inicio, dta_termino } = periodo;

      const pdf = new ApuracaoIcmsPDF(
        apuracaoGridItems,
        { dta_inicio, dta_fim: dta_termino },
        infoLoja,
        aliquotas,
      );

      pdf.generatePDF();
    }
  }, [aliquotas, apuracaoGridItems, infoLoja, periodo]);

  return (
    <CustomButtonNovo
      onClick={handlePrint}
      label="IMPRIMIR"
      icon={FaPrint}
      style={{ backgroundColor: '#666666' }}
      disabled={
        disableButton.print || apuracaoGridItems.length <= 0 || !infoLoja
      }
    />
  );
};
