import styled from 'styled-components';

export const ContentSearchFilters = styled.section`
  display: flex;
  flex-direction: column;

  .section-input-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    .div-input-date,
    .div-buttons {
      display: flex;
      gap: 1rem;

      @media screen and (max-width: 992px) {
        width: 100%;
        gap: 0rem;
        justify-content: space-between;
      }
    }

    .div-input-date {
      input {
        width: 9.375rem;
      }

      @media screen and (max-width: 992px) {
        input {
          width: 12.5rem;
        }
      }
    }

    .div-buttons {
      margin-top: 1.875rem;

      @media screen and (max-width: 992px) {
        margin-top: 1.25rem;
      }
    }
  }
`;
