import api from '~/services/api';
import { ResponseApuracaoProps, ResponseFormattedProps } from '../types';

const indexApuracao = async (
  lojas: number[],
  dta_inicio: string,
  dta_fim: string,
): Promise<ResponseFormattedProps<ResponseApuracaoProps>> => {
  const { data } = await api.get<ResponseFormattedProps<ResponseApuracaoProps>>(
    '/apuracao-icms',
    {
      params: { lojas, dta_inicio, dta_fim },
    },
  );
  return data;
};

export const serviceApuracao = { indexApuracao };
