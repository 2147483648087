import styled from 'styled-components';

export const ContainerApuracao = styled.article`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;

    .content-print-button {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
    }

    .cell-apuracao-green {
      background-color: #c0dcc0;
    }
  }
`;
