import { Box, Tooltip } from '@material-ui/core';
import { DataGrid, GridColDef, GridColumns } from '@material-ui/data-grid';
import React from 'react';
import { useApuracaoIcms } from '../../hooks/useApuracaoIcms';

export const GridIcms: React.FC = () => {
  const { isFetching, apuracaoGridItems } = useApuracaoIcms();

  const createColumn = (
    field: string,
    headerName: string,
    width = 150,
    align: 'left' | 'center' | 'right' = 'right',
    cellClassName?: string,
  ): GridColDef => ({
    field,
    headerName,
    headerAlign: align,
    align,
    width,
    cellClassName,
  });

  const tabColumns: GridColumns = [
    { field: 'id', hide: true },
    { field: 'cfop', headerName: 'CFOP', headerAlign: 'left', align: 'left' },
    createColumn('val_contabil', 'Val. Contábil Entr.', 170),
    createColumn('val_base_calculo', 'Val. Base Entr.'),
    createColumn('val_isentas', 'Val. Isentas Entr.', 170),
    createColumn('val_outras', 'Val. Outras Entr.', 160),
    createColumn(
      'val_imposto_credito',
      'Val. Imposto Créd.',
      180,
      'right',
      'cell-apuracao-green',
    ),
    createColumn('val_contabil_sai', 'Val. Contábil Saída', 180),
    createColumn('val_base_calculo_sai', 'Val. Base Saída', 160),
    createColumn('val_isentas_sai', 'Val. Isentas Saída', 175),
    createColumn('val_outras_sai', 'Val. Outras Saída', 170),
    createColumn(
      'val_imposto_debito',
      'Val. Imposto Déb.',
      170,
      'right',
      'cell-apuracao-green',
    ),
    createColumn('val_icms_st', 'ICMS-ST', 120),
  ];

  const renderTooltipColumns = (params: any) => (
    <Tooltip title={`CFOP: ${params.row.cfop}`} placement="bottom">
      <div style={{ width: '100%', height: '100%' }}>{params.value}</div>
    </Tooltip>
  );

  const columnsWithTooltips = tabColumns.map((col) => ({
    ...col,
    renderCell: renderTooltipColumns,
  }));

  return (
    <Box sx={{ height: 350, width: 'auto' }}>
      <DataGrid
        rows={apuracaoGridItems}
        columns={columnsWithTooltips}
        loading={isFetching}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        hideFooter
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar colunas',
        }}
      />
    </Box>
  );
};
