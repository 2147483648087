import React from 'react';
import { ApuracaoIcmsContent } from './ApuracaoIcmsContent';
import { ApuracaoIcmsProvider } from './contexts/ApuracaoIcmsContext';

export const ApuracaoIcms: React.FC = () => {
  return (
    <ApuracaoIcmsProvider>
      <ApuracaoIcmsContent />
    </ApuracaoIcmsProvider>
  );
};
