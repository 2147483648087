import React from 'react';
import { FaEraser } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import Loja from '~/components/Loja';
import { InputDate } from '~/components/NovosInputs';
import { ContentSearchFilters } from './styles';
import { useApuracaoIcms } from '../../hooks/useApuracaoIcms';

export const SearchFilters: React.FC = () => {
  const {
    formTela,
    disableInput,
    disableButton,
    handleSearch,
    handleClear,
    handleCancel,
    handleChangeLoja,
    isFetching,
  } = useApuracaoIcms();

  const {
    register,
    control,
    formState: { errors },
    clearErrors,
  } = formTela;

  return (
    <ContentSearchFilters>
      <Loja
        isMulti
        onChange={(ev) => {
          handleChangeLoja(Array.isArray(ev) ? ev : [ev]);
        }}
        disabled={disableInput.lojas}
      />
      <section className="section-input-buttons">
        <div className="div-input-date">
          <InputDate
            name="dta_inicio"
            register={register}
            control={control}
            isError={!!errors.dta_inicio}
            label="Início:"
            disabled={disableInput.datas}
            onInput={() => {
              clearErrors('dta_inicio');
            }}
          />
          <InputDate
            name="dta_fim"
            register={register}
            control={control}
            isError={!!errors.dta_fim}
            label="Término:"
            disabled={disableInput.datas}
            onInput={() => {
              clearErrors('dta_fim');
            }}
          />
        </div>
        <div className="div-buttons">
          <CustomButtonNovo
            onClick={async () => {
              await handleSearch();
            }}
            label="Pesquisar"
            icon={IoIosSearch}
            disabled={disableButton.search}
            showLoading={isFetching}
          />
          <CustomButtonNovo
            disabled={disableButton.cancel}
            onClick={handleCancel}
            label="Cancelar"
            icon={MdOutlineCancel}
            variant="cancel"
          />
          <CustomButtonNovo
            onClick={handleClear}
            disabled={disableButton.clear}
            label="Limpar"
            icon={FaEraser}
            variant="clear"
          />
        </div>
      </section>
    </ContentSearchFilters>
  );
};
